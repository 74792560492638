import { Component,Inject,OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { EntityActivationService } from '@miticon-ui/mc-core';
import { ToastrService } from 'ngx-toastr';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-mc-profile-activation-dialog',
  templateUrl: './mc-profile-activation-dialog.component.html',
  styleUrl: './mc-profile-activation-dialog.component.scss',
})
export class McProfileActivationDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  form!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { entityId: number },
              public dialogRef: MatDialogRef<McProfileActivationDialogComponent>,
              private fb: FormBuilder,
              private entityActivationService: EntityActivationService,
              private toastr: ToastrService,
              private tS: TranslateService) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      activationFlg: ['', Validators.required],
    });
  }

  onConfirm() {
    if (!this.form.valid) return;

    const activationFlg = this.form.get('activationFlg')?.value;
    const entityId = this.data.entityId;
    const activationMethod = activationFlg === 'activate' ? this.entityActivationService.activateProfile(entityId)
                                                          : activationFlg === 'deny' ? this.entityActivationService.denyProfile(entityId)
                                                          : null;
    if (activationMethod) {
      activationMethod.subscribe({
        next: () => {
          this.toastr.success(this.tS.instant('cc.entity.profile-send-successfully'));
          this.dialogRef.close(true);
        },
        error: (error) => this.toastr.error(error.error.message),
      });
    }
  }
}
